import { useTranslation } from 'next-i18next';
import { Button, Modal } from '@components/common';
import { CheckIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon, HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline';
import { ApiJobDetailOk, ApiJobUserDetailOk } from '@type/v1-api-types';
import { useJobTalentActions } from '@hooks/features/useJobTalentActions';
import {
  useContext, useEffect, useState,
} from 'react';
import { JobTrackingContext } from '@components/contexts/JobTrackingContext';
import { JobPlacement } from '@type/job-params';
import { clsx } from 'clsx';
import { useAuthClient } from '@hooks/common/useAuthClient/useAuthClient';
import { useBjModal } from '@hooks/common/useBjModal';
import { InterviewQuestionsForm } from '@components/templates/forms/interview/InterviewQuestions';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';

// Interface
// ************************************
export interface JobActionsProps {
  job: ApiJobDetailOk & ApiJobUserDetailOk,
  isSinglePage?: boolean
}

/**
 * @description Job detail - Job Actions component.
 * - **job** - ApiJobDetailOk. The job detail.
 */
export const JobActions = (props: JobActionsProps) => {
  // Destructure props
  const { job, isSinglePage } = props;

  // Translation
  const { t } = useTranslation('common');

  const { locale } = useExtendedRouter();

  // Check whether the user is authenticated
  // **************************************
  const { isSafeAuth } = useAuthClient();

  // Get tracking context data
  // **************************************
  const {
    requestId,
  } = useContext(JobTrackingContext) || {};

  const { isOpen: nestedChildOpen, toggleModal: nestedChildToggle } = useBjModal();

  // Job Talent Actions Hook
  // *****************************************
  const {
    isFavorite,
    isBlocked,
    isLoadingFavoritesAction,
    isLoadingBlockedAction,
    addToFavorites,
    removeFromFavorites,
    addToBlocked,
    removeFromBlocked,
    applyToJob,
    isApplying,
    hasApplied,
    setShouldFetchBulkApplyFeed,
  } = useJobTalentActions({
    jobId: job.jobId?.toString(),
    jobTitle: job.title,
    company: job.employer?.employerName,
    jobSlug: job.slug,
    likedProp: !!job.liked,
    dislikedProp: !!job.disliked,
    appliedProp: !!job.applied,
    applyCallback: () => {
      // toggleBulkApplyModal();
      // togglePersonalDataModal();
      // toggleUserMessageModal();
    },
    requestId,
    placement: JobPlacement.DETAIL,
    hasOwnApplyUrl: job.hasOwnApplyUrl,
    withEasyApply: true,
    jobQuestions: job?.questions,
    interviewModalTrigger: !isSinglePage ? nestedChildToggle : undefined,
    allowAfterApply: !job?.workplaceJob,
  });

  // Helper Object that stores the classes needed for tracking
  // *****************************************
  const trackingClasses = {
    like: 'tr-job-like',
    disliked: 'tr-job-dislike',
  };

  const [trackingClassesApply, setTrackingClassesApply] = useState<string>('');

  // Update trackingClassesApply if the user is logged in
  // *****************************************
  useEffect(() => {
    setTrackingClassesApply(clsx('tm-job-apply', !isSafeAuth && 'tr_easy_apply_login'));
  }, [isSafeAuth]);

  // Helper Classes
  // *****************************************
  const buttonBaseClasses = 'px-5 !transition !duration-200 lg:!px-7';
  const buttonDefaultClasses = '!bg-primary/10 !text-primary';
  const buttonLikeClasses = clsx(buttonBaseClasses, !isFavorite && buttonDefaultClasses, 'hover:!bg-warning hover:!text-white', trackingClasses.like);
  const buttonDislikeClasses = clsx(buttonBaseClasses, !isBlocked && buttonDefaultClasses, 'hover:!bg-error hover:!text-white', trackingClasses.disliked);
  const buttonIconClasses = 'w-5 h-5';
  const buttonSpinnerClasses = 'h-5 w-5 mx-0 mx-0 text-white';

  // Render component
  // *****************************************
  return (
    <>
      <div className={`${isSinglePage ? 'bottom-16 md:bottom-2' : 'bottom-2'} sticky z-2 -ml-2 mt-4 flex space-x-2 rounded-full bg-surface px-2 py-2.5 shadow-jobActionsContainer lg:bottom-4 lg:-mx-4 lg:mt-8`}>
        {hasApplied ? (
          <>
            {/* Already applied */}
            <Button type="button" rounding="full" className={clsx('pointer-events-none grow !py-2.5 !duration-0', { 'animate-appear': hasApplied })}>
              <CheckCircleIcon className="-ml-1 mr-2 size-6" />
              { t('job.applied') }
            </Button>

            { (job?.questions && job?.questions?.length > 0 && !isSinglePage) && (
              <Modal
                isOpen={nestedChildOpen}
                onClose={nestedChildToggle}
                header={t('apply.wizard.mini_interview.title')}
                size="md"
              >
                <Modal.Body>
                  <InterviewQuestionsForm
                    jobQuestion={job?.questions}
                    jobSlug={job?.slug}
                    locale={locale}
                    onSaveSuccess={() => {
                      nestedChildToggle();
                      setShouldFetchBulkApplyFeed(true);
                    }}
                  />
                </Modal.Body>
              </Modal>
            )}
          </>
        ) : (
          <>
            {/* Blocked / Disliked button */}
            <Button
              type="button"
              rounding="full"
              color={isBlocked ? 'error' : 'primary'}
              isLoading={isLoadingBlockedAction}
              className={buttonDislikeClasses}
              spinnerClass={buttonSpinnerClasses}
              onClick={isBlocked ? removeFromBlocked : addToBlocked}
            >
              {!isLoadingBlockedAction && <HandThumbDownIcon className={buttonIconClasses} />}
            </Button>

            {/* Favorites / Like button */}
            <Button
              type="button"
              rounding="full"
              color={isFavorite ? 'warning' : 'primary'}
              isLoading={isLoadingFavoritesAction}
              className={buttonLikeClasses}
              spinnerClass={buttonSpinnerClasses}
              onClick={isFavorite ? removeFromFavorites : addToFavorites}
            >
              {!isLoadingFavoritesAction && <HandThumbUpIcon className={buttonIconClasses} />}
            </Button>

            {/* Apply button */}
            <Button
              type="button"
              className={clsx('grow !transition !duration-200', trackingClassesApply)}
              color="secondary"
              rounding="full"
              isLoading={isApplying}
              onClick={applyToJob}
            >
              {!isApplying && <CheckIcon className="-ml-1 mr-2 size-6" />}
              <span>{ t('job.tooltip.apply') }</span>
            </Button>
          </>
        )}
      </div>
    </>
  );
};
